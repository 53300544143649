import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark} from '@fortawesome/free-solid-svg-icons'
import Cookies from 'universal-cookie';
import { ThemeContext } from '../App/ThemeContext';
import { Scanner } from '@yudiel/react-qr-scanner';
import { apiUrl } from '../App/Constants';

export default function CardVoucher() {
  const { theme } = useContext(ThemeContext);
  const primaryColor = theme.primary;
  const [location, setLocation] = useState("");
  const [itemType, setitemType] = useState("");
  const [mobileType, setmobileType] = useState("");
  const [itemList, setList] = useState([])
  const [selectedItemList, setSelectedItemList] = useState([])
  const [locationList, setLocationList] = useState([])
  const [typeLists, setTypeList] = useState([])
  const [itemsTitle, setTitle] = useState("Nincs hozzáadott termék")
  const cookies = new Cookies();
  const [isValid, setIsValid] = useState(null);
  const [item, setItem] = useState("");
  const handleSubmit = () => {
    if (localStorage.getItem("isUserLoggedIn") === "true") {
      if (selectedItemList.length > 0) {
        var orderInfo = ""
        selectedItemList.forEach(element => {
          orderInfo = orderInfo + " | "
          orderInfo = orderInfo + element.itemType
          orderInfo = orderInfo + " - "
          orderInfo = orderInfo + element.mobileType
          orderInfo = orderInfo + " | "
        });
        if (window.confirm("Biztosan felviszed ezt a kártyás vásárlást? | " + location + " - " + orderInfo)) {
         var decodedUser = jwt_decode(localStorage.getItem("jwt"));
      var today = new Date(),
        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      var json = JSON.stringify(selectedItemList)
      selectedItemList.forEach(element => {
       axios.post(apiUrl + 'inventoryRemove.php?itemName=' + element.itemType + '&phoneType=' + element.mobileType + '&location=' + location)
      });
       axios.post(apiUrl + 'cardvoucher.php?time=' + date + '&location=' + location + '&user=' + decodedUser.id + '&userName=' + decodedUser.name, json)
       axios.patch(apiUrl + 'cardQr.php?qrstring=' + scannedData)
      alert("Eladás rögzítve!");
       }
        
      } else {
        alert("Adj hozzá legalább 1 terméket!")
      }
      
    } else {
      window.location = '/login'
    }
    }
    const [showQrReader, setShowQrRead] = useState(false);
    const setShowQrReader = () => {
      setShowQrRead(!showQrReader);
      setIsValid(null);
    };
  const [scannedData, setScannedData] = useState(null);
  function decodeData(base64) {
    // URL-barát base64 visszaalakítása normál base64 formátumra
    let base64String = base64.replace(/-/g, '+').replace(/_/g, '/');
    // Base64 dekódolás
    let utf8Data = atob(base64String);
    // UTF-8 dekódolás
    return decodeURIComponent(escape(utf8Data));
  }
  const handleScan = (data) => {
    if (data) {
      const splitedData = data.split("?")[1];
      setScannedData(splitedData);
      setShowQrReader(false);

      // API hívás axios-szal
      axios.get(apiUrl + `cardQr.php?qrstring=${splitedData}`)
        .then(response => {
          setIsValid(response.data[0]["isvalid"] === "1" ? true : false);
          const data = decodeData(response.data[0]["qrstring"])
          const items = JSON.parse(data);
          setItem(items.cardType)
          if (items.cardType == "Fekete kártya") {
             setList(itemList.filter(item => item.itemName === "Slim UV" || item.itemName === "Slim Privacy Matt" || item.itemName === "Slim Pro Case" || item.itemName === "Slim Pro Magsafe Case"))
          } else if (items.cardType == "Fehér kártya") {
            setList(itemList.filter(item => item.itemName === "Slim HD" || item.itemName === "Slim Self-healing" || item.itemName === "Slim Case"))
          }

        })
        .catch(error => {
          console.error('Hiba a QR kód olvasásakor:', error);
        });
    }
  };

  const handleType = async e => {
    if (localStorage.getItem("isUserLoggedIn") === "true") {
      setitemType(e.target.value)
      if (!e.target.value.includes("Case")) {
         setTypeList(["-"])
        setmobileType("-")
      } else {
        
        var pTypes = []
        itemList.forEach(element => {
          if (element.itemName === e.target.value) {
            JSON.parse(element.phoneTypes).forEach(types => {
              pTypes.push(types)
            })
          }
        });
        setTypeList(pTypes)
      }
    } else {
      window.location = '/login'
    }
    }
  
  const handleAddItem = () => {
    if (mobileType !== "" & itemType !== "") {
      let item = {id: selectedItemList.length + 1, itemType: itemType, mobileType: mobileType}
    let newItem = selectedItemList.concat(item)
      setSelectedItemList(newItem)
      if (newItem.length > 0) {
      setTitle("Hozzáadott termékek:")
    } else {
      setTitle("Nincs hozzáadott termék")
    }
    setmobileType("")
    setitemType("")
    }
  }

  const handleRemove = id => {
    const items = selectedItemList.filter((item) => item.id !== parseInt(id))
    setSelectedItemList(items)
    if (items.length > 0) {
      setTitle("Hozzáadott termékek:")
    } else {
      setTitle("Nincs hozzáadott termék")
    }
  }   
  const handleLocation = location => {
    setLocation(location)
    let d = new Date();
      d.setTime(d.getTime() + (720*60*1000));
    cookies.set('location', location, { path: '/', expires: d });
  } 

  const fetchData = () => {
      fetch(apiUrl + "newlist.php")
        .then(response => {
          return response.json()
        })
        .then(data => {
              setList(data.itemTypes.filter(item => item.itemName !== "Fekete kártya" && item.itemName !== "Fehér kártya" && item.itemName !== "Tisztítás kártya" && item.itemName !== "Powerbank"))
              setLocationList(data.locations)
              setLocation(cookies.get('location'))
        })
  }
  useEffect(() => {
    fetchData();
  }, []);
  var decodedUser = jwt_decode(localStorage.getItem("jwt"));
  return(
    <div>
      <h2 style={{color: primaryColor, marginBottom: "25px"}}>Kártya beváltás</h2>
      <button onClick={() => setShowQrReader(!showQrReader)} style={{ marginBottom: "20px", background: primaryColor, color: 'white', padding: '10px', border: 'none', borderRadius: '5px' }}>
        {showQrReader ? 'QR kód olvasó bezárása' : 'QR kód olvasó megnyitása'}
      </button>
      {isValid !== null && (
        <div style={{fontSize: "14px", margin: "10px", background: isValid ? "green" : "red", color: "white", padding: "10px", width: "50%", margin: "auto", marginBottom: "10px", borderRadius: "15px"}}>
          {isValid ? 'Érvényes kártya ('+item+')' : 'Érvénytelen kártya ('+item+')'}
        </div>
      )}
      {showQrReader && (
        <Scanner
        legacyMode={true}
        onScan={(result) => handleScan(result[0].rawValue)}
        onError={(error) => window.alert("Hibás QR kód")}
      />
      )}
          <form onSubmit={handleSubmit}>
              <p className='inputLabel'>Helyszín</p>
              <select className='mobileInput' value={location} onChange={e => handleLocation(e.target.value)} required>
                  <option disabled></option>
                  {locationList.map((val, key) => {
                        return (  
                        <option value={val.locationName}>{val.locationName}</option>
                        )})}
        </select>
        <div className='itemList' style={{borderColor: primaryColor, width: "200px"}}>
        <p className='inputLabel' style={{width:"100%"}}>{itemsTitle}</p>
        <div className='items' style={selectedItemList.length < 1 ? {display:"none"}:{}}>
        {selectedItemList.map((val, key) => {
          return (
            <div key={key}>
              <div className="item" style={{backgroundColor: primaryColor, paddingLeft: "10px"}} id={val.id} onClick={e => handleRemove(e.target.id)}>{val.itemType} {val.mobileType != "-" && ("- " + val.mobileType)} <FontAwesomeIcon className='famenu' aria-hidden="true" icon={faCircleXmark} /></div>
            </div>
            
          )
        })}</div>
        </div>
              <div className='addItemDiv'>
            <p className='inputLabel'>Termék</p>
              <select className='mobileInput' value={itemType} onChange={e => handleType(e)}>
                  <option disabled></option>
                  {itemList.map((val, key) => {
                        return (  
                            <option value={val.itemName}>{val.itemName}</option>
                        )})}
              </select>
            
            <p className='inputLabel'>Típus</p>
              <select className='mobileInput' value={mobileType}  onChange={e => setmobileType(e.target.value)}>
                  <option disabled></option>
            {typeLists.map((object, i) => {
                          return (
                        <option value={object}>{object}</option>
                        )})}
        </select>
        <br></br>
        <button type="button" className='mobileInput submitbutton' style={{marginBottom:"10px", background: primaryColor}} onClick={handleAddItem}>Termék hozzáadása</button>
        </div>

            <hr className='hrc'/>
            {selectedItemList.length > 0 && (<button type='submit' style={{background: primaryColor}} className='mobileInput submitbutton'>Adat felvitele</button>)}
          </form>
    </div>
  );

}