import axios from 'axios';
import { apiUrl } from '../../Components/App/Constants';

// API lekérdezés a lokációkhoz
export const fetchLocations = async () => {
  const response = await axios.get(apiUrl + 'locations.php');
  return response.data;
};

// API lekérdezés egy adott lokáció készletéhez
export const fetchInventoryByLocation = async (locationId) => {
  const response = await axios.get(apiUrl + 'inventory.php?location_id=' + locationId);
  return response.data;
};

// API hívás a raktárkészlet frissítéséhez Irodánál
export const updateInventory = async (locationId, updates) => {
  await axios.post(apiUrl + 'inventory.php', { location_id: locationId, updates: updates });
};

// API lekérdezés a globális szükséges készlethez (item_id és subtype szerint)
export const fetchRequiredQuantities = async (locationId) => {
  const response = await axios.get(apiUrl + 'required_quantites.php?location_id=' + locationId);
  return response.data;
};

// API hívás a globális szükséges készlet frissítéséhez (item_id és subtype szerint)
export const updateRequiredQuantities = async (requiredQuantities, locationId) => {
  await axios.post(apiUrl + 'required_quantites.php', { requiredQuantities: requiredQuantities, location_id: locationId });
};