import React, { useEffect, useState, useContext } from 'react';
import './Leltar.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Statisztika from '../Statisztika/Statisztika';
import { ThemeContext } from '../App/ThemeContext';
import { faLocationPin, faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChartComponent from '../Leltar/AiStat';
import { apiUrl } from '../App/Constants';

const UsingFetch = () => {
  const { theme } = useContext(ThemeContext);
  const primaryColor = theme.primary;
  const [items, setItems] = useState([])
  const [cardVoucherItems, setcardVoucherItems] = useState([])
  const [warranty, setWarranty] = useState([])
  const [scrapItems, setscrapItems] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [filter, setFilter] = useState("Összes")


  const fetchData = (time, endTime) => {
    if (localStorage.getItem("isUserLoggedIn") === "true") {
      var decoded = jwt_decode(localStorage.getItem("jwt"));
    var options = {  
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem("jwt")
      }
    }
    
    var url = apiUrl + "storage.php?date=" + time + "&endDate=" + endTime
    if (decoded.priv === 2) {
      var id = "&onlyuserID=" + decoded.id
      url = url + id
    }
      fetch(url, options)
        .then(response => {
          return response.json()
        })
        .then(data => {
          setItems(data)
        }).then(() => {
          var url = apiUrl + "cardvoucher.php?date=" + time + "&endDate=" + endTime
          if (decoded.priv === 2) {
            var id = "&onlyuserID=" + decoded.id
            url = url + id
          }
            fetch(url, options)
              .then(response => {
                return response.json()
              })
              .then(data => {
                setcardVoucherItems(data)
              })
        })
        .catch(error => {
         window.location = '/login'
        })
      
    } else {
      window.location = '/login'
    }
    
    
  }
  const fetchWarrantyData = (time, endTime) => {
    if (localStorage.getItem("isUserLoggedIn") === "true") {
    var options = {  
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }
    
  if (endDate === undefined) {
  setEndDate(time)
  }
    var url = apiUrl + "garanciastat.php?date=" + time + "&endDate=" + endTime
    
      fetch(url, options)
        .then(response => {
          return response.json()
        })
        .then(data => {
          setWarranty(data)
        })
        .catch(error => {
         //window.location = '/login'
        })
      
    } else {
      window.location = '/login'
    }
    
    
  }
  const fetchScrap = (time, endTime) => {
    if (localStorage.getItem("isUserLoggedIn") === "true") {
      var decoded = jwt_decode(localStorage.getItem("jwt"));
    var options = {  
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem("jwt")
      }
    }
    
    var url = apiUrl + "replace.php?date=" + time + "&endDate=" + endTime
    if (decoded.priv === 2) {
      var id = "&onlyuserID=" + decoded.id
      url = url + id
    }
      fetch(url, options)
        .then(response => {
          return response.json()
        })
        .then(data => {
          setscrapItems(data)
        })
        .catch(error => {
         console.log(error)
        })
      
    }
    
    
  }
  const addFilter = () => {
    if (localStorage.getItem("isUserLoggedIn") === "true") {
      var decoded = jwt_decode(localStorage.getItem("jwt"));
      if (decoded.priv === 1 || decoded.priv === 0) {
        return (
          <div>
          <p className='inputLabel'>Helyszín</p>
          <select className='mobileInput' value={filter} onChange={e => setFilter(e.target.value)}>
            <option value="Összes">Összes</option>
            <option value="Westend">Westend</option>
            <option value="Westend II">Westend II</option>
            <option value="Westend III">Westend III</option>
            <option value="Westend bolt">Westend bolt</option>
            <option value="Andrássy üzlet">Andrássy üzlet</option>
            <option value="Pólus Center">Pólus Center</option>
            <option value="Campona">Campona</option>
            <option value="Webshop">Webshop</option>
          </select>
          </div>
        )
      }
    }
  }
  const setDataAndFetch = date => {
    setStartDate(date)
    if (date > endDate) {
    setEndDate(date)
    }
    fetchData(format(date, "yyyy-MM-dd"))
    fetchWarrantyData(format(date, "yyyy-MM-dd"))
  }
  function handleDelete(id, allItem, location) {
    var decoded = jwt_decode(localStorage.getItem("jwt"));
      if (decoded.priv === 1 || decoded.priv === 0) {
    if(window.confirm("Biztosan törölni szeretnéd?")){
      allItem.forEach(element => {
        axios.post(apiUrl + 'inventoryadd.php?itemName=' + element.itemType + '&phoneType=' + element.mobileType + '&location=' + location )
      });
      fetch(apiUrl + `storage.php?id=${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('A törlés sikertelen');
        }
        fetchData(format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd"));
        // Frissítheted az állapotot, ha szükséges
      })
      .catch(error => {
        console.error('Hiba történt a törlés során:', error);
      });

    }
  }
  }
const setendDateAndFetch = date => {
  setEndDate(date) 
  if (date < startDate) {
  setStartDate(date)
  }
  }
  const ItemOfPersons = itemsFiltered => {
    if (itemsFiltered.length < 400) {
      return (
          <div className='productTable' >
          {itemsFiltered.map((val, key) => {
          var allItem = JSON.parse(val.items)
          return (
            <div key={key} className="productRow" style={{ borderColor: primaryColor, borderWidth: 3 }} onClick={() => handleDelete(val.id, allItem, val.location)}>
              <div className='centerDiv'>
                <div className='halfdiv'>
                
                  <div className='location' style={{ backgroundColor: primaryColor}}><div className='locationIcon' style={{ backgroundColor: 'white'}}><FontAwesomeIcon style={{ color: primaryColor, fontSize: "11px", padding: "5px", paddingLeft: "6px"}} icon={faLocationPin} /></div><div className='locationName'>{val.location}</div></div>
                </div>
                <div className='halfdiv timediv'>
                <div className='timetext' style={{ backgroundColor: "white", color:"black"}}><div className='clockIcon' style={{ backgroundColor: primaryColor}}><FontAwesomeIcon style={{ color: "white", fontSize: "11px", padding: "5px", paddingLeft: "5px"}} icon={faCalendar} /></div><div className='timeName'>{val.time}</div></div>
                  <p className='timetable'></p>
                </div>
                <hr style={{ color: primaryColor, margin: "5px"}}></hr>
                <div className='financialDiv' style={{marginBottom: "5px"}}>
                <p >{formatPrice(val.price)} Ft - {val.paymentType}</p>
              </div>
              </div>
              
              {allItem.map((val1, key) => {
                return (
                  <p className='productType perProd'><b>{val1.itemType}</b> {val1.mobileType != "-" && "(" + val1.mobileType + ")"} {val1.itemPrice != undefined && " - " + val1.itemPrice + " Ft"}</p>
                )
                
              })}
              <br></br>
              {val.warranty == 1 && (<p className='warranty' style={{ color: primaryColor, borderColor: primaryColor}}> Garanciális </p>)}
              {val.warrantyReplace != null && (<p className='warranty' style={{ color: primaryColor, borderColor: primaryColor}}> Garanciális csere</p>)}
              {val.slimmerEmail != null && (<p className='warranty' style={{ color: primaryColor, borderColor: primaryColor}}> Slimmer</p>)}
              <center><hr style={{ color: primaryColor, margin: "5px", width: "20px"}}></hr></center>
              <p className='userName'> {val.userName} </p>
              
            </div>
            
          )
        })}
            </div>
              )
    }
    
  }
  const formatPrice = (value) => {
    // Remove any existing spaces to get the plain number
    const plainNumber = value.replace(/[\s,]/g, '');
    // Convert the plain number to a numeric value
    const numericValue = parseFloat(plainNumber);
    // Check if the numeric value is a valid number
    if (!isNaN(numericValue)) {
      // Use toLocaleString to format the number with spaces as thousand separators
      return numericValue.toLocaleString('hu');
    } else {
      // Return the original value if it's not a valid number
      return value;
    }
  };
  const createItemList = () => {
    var itemsFiltered = items
    var newScraps = scrapItems
    var cardVoucheredItemsfiltered = cardVoucherItems
    if (filter !== "Összes") {
      itemsFiltered = itemsFiltered.filter(items => items.location === filter)
      newScraps = scrapItems.filter(items => items.location === filter)
      cardVoucheredItemsfiltered = cardVoucherItems.filter(items => items.location === filter)
    }
      return (
        <div>
          <br></br>
          
          
          <p className='inputLabel'>Kezdődátum</p>
          <DatePicker classname="datepicker" selected={startDate} onChange={(date) => setDataAndFetch(date)} />
          <p className='inputLabel'>Végedátum</p>
          <DatePicker classname="datepicker" selected={endDate} onChange={(date) => setendDateAndFetch(date)} />
          {addFilter()}
          {itemsFiltered.length !== 0 && (<Statisztika parentToChild={itemsFiltered} scraps={newScraps} startDate={startDate} warranty={warranty} cardVoucheredItems={cardVoucheredItemsfiltered}/>)}
          <div className='productTable' id="leltar">
          <h2 style={{color: primaryColor, marginBottom: "25px"}} >Leltár</h2>
          <a href='#statisztika' className='gotoBlockLink' style={{ borderColor: primaryColor, color: primaryColor}}>Statisztika</a>
          {itemsFiltered.length === 0 ? (
        <div className="loader" style={{ borderTopColor: primaryColor}} />
      ) : (
        ItemOfPersons(itemsFiltered)
      )}
            <hr></hr>
            
          </div>
        </div>
      )
      
  }
  useEffect(() => {
    fetchData(format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd"));
    fetchWarrantyData(format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd"));
    fetchScrap(format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd"));
    const intervalCall = setInterval(() => {
      fetchData(format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd"));
      fetchWarrantyData(format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd"));
      fetchScrap(format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd"));
    }, 30000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, [startDate, endDate]);
  return (
     createItemList()
   )
  
}
export default UsingFetch